/* ============================================================================================= 
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Harlan
==============================================================================================*/

@import "sass-lib";

@media all and (max-width: 1740px) {
 
/* header
----------------------------------------------------------------------------------------------*/
.burger-menu {left: 30px;}
header {
	.logo {margin: 32px 0 0 120px; width: 160px;}
	// nav {
	// 	margin: 47px 15px 0 15px;
	// 	a {font-size: 15px; margin-right: 18px;}
	// }
	// .lang {
	// 	margin: 47px 15px 0 0;
	// 	a {font-size: 15px;}
	// }
	// .right {
	// 	a.btn {
	// 		max-width: 180px; height: 45px; padding: 0 16px; margin-top: 30px; font-size: 14px;
	// 		&:after {width: 25px; height: 25px; background-size: 100%; left: 12px;display: none;}
	// 	}
	// 	a.phone, a.online-payment {
	// 		margin: 40px 15px 0 0; font-size: 14px;background-size: 20px;padding-left: 30px;
	// 	}
	// 	.account-nav {
	// 		padding: 0 10px 0 15px;
	// 		.drop {
	// 			a {padding: 18px 25px 15px; font-size: 14px;}
	// 		}
	// 	}
	// }
	// .search {
	// 	margin: 43px 10px 0 0;
	// }
	&:after {left: 100px;}
	.header-right {
		margin:0 30px; 
	}
}


/* homepage
----------------------------------------------------------------------------------------------*/

.banner-home {
	padding-left: 101px;
	.slick-dots {left: -60px;}
	.wrap-caption {
		width: 1170px;
	}
	.caption {
		width: 460px; height: auto;  bottom: 90px; top: auto;
		h1,h2 {font-size: 50px; line-height: 52px;}
	}
	a.scroll-down {left: 44px;}
	&.banner-std {
		.wrap-caption {left: 100px;}
		.location-right {width: 520px;}
		.map-location {padding-right: 520px;}
		&:after {left: 100px;}
	}
}

.home-sec {
	&.h-prod {
		&:before {left: 40px;}
		.wrapper {width: 1280px;}
		.slider {
			width: 740px; margin-left: 80px;
			.slick-list {padding-bottom: 10px;}
			.box {
				padding: 0 12px;
				&:after {
					left: 12px; right: 12px; bottom: 0;
				}
			}
			.text {bottom: -10px;}
		}
	}
	&.h-updates {
		padding: 70px 0;
		.list {
			.box {width: 255px; margin-right: 50px;}
		}
	}
	&.h-testi {
		.box {
			@include borderRadius(5px);
			.img {
				width: 220px; height: 205px;@include borderRadius(5px 0 0 5px); overflow: hidden;
				img {position: relative; object-fit: cover; width: 100%; height: 100%;}
			}
			.text {margin: 25px 0 0 25px; width: 290px;}
		}
	}
	&.h-contact {
		.left, .right{width: 48%;}
		.left {
			#home-map {height: 575px;}
		}
	}
}

.box-form {
	.row {
		.col {width: 260px; margin-right: 35px;}
	}
}


.content-std {
	.list-location {
		.box {
			.img {
				height: 400px; width: 53%;
				img {display: block; position: relative; object-fit: cover; height: 100%; width: 100%;}
			}
		}
	}
}

.tagg {
	padding-left: 65px;
	.lbl {font-size: 17px; top: 10px;}
	ul {
		li {
			a {font-size: 16px;}
		}
	}
}


} /* end media */

@media all and (max-width: 1680px) {

/* global
----------------------------------------------------------------------------------------------*/



/* header
----------------------------------------------------------------------------------------------*/

.home-sec {
	.wrapper {width: 1170px;}
	&.h-about {max-width: 1280px; background-size: 400px !important; padding: 80px 0;}
}


/* homepage
----------------------------------------------------------------------------------------------*/

.content-std {
	.wrapper {width: 1280px;}
	.box-register {width: 1100px;}
	&.about {
		.list-col {
			.col {margin-right: 80px;}
		}
		.side-image .img {left: -230px;}
		.inner-about {width: 650px;}
	}
	.list-prod {
		.box {margin-right: 47px;}
	}
	.price-type {
		.box {
			width: 300px;
			.head {height: 125px; padding: 25px 30px 0;}
		}
	}
	.support-facility {
		.left {width: 700px;}
		.right { width: 535px;}
	}

	.box-contact {
		.right { width: 635px;padding-left: 85px;}
		.box-form {
			.row {
				.col {width: 300px;}
			}
		}
	}
}

/* middle
----------------------------------------------------------------------------------------------*/


/* footer
----------------------------------------------------------------------------------------------*/

footer .wrapper {width: 1280px;}

} /* end media */


@media all and (max-width: 1480px) {

/* global
----------------------------------------------------------------------------------------------*/



/* header
----------------------------------------------------------------------------------------------*/
header {
	.logo {
		width: 150px;
	}
	nav {
		a {
			margin-right: 14px;
		}
	}
	.header-right {
		margin-left: 20px;
	}
}


/* homepage
----------------------------------------------------------------------------------------------*/


/* middle
----------------------------------------------------------------------------------------------*/


/* footer
----------------------------------------------------------------------------------------------*/



} /* end media */

